.App {
  text-align: center;
  font-family: "Roboto";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
display: flex;
flex-wrap: wrap
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.group{
  background-color: rgba(6, 52, 170, 0.5) !important;
  border-color: #0634aa !important;
}
.searchBtn{
 float:right;
 padding-bottom: 50px;

}

.leaflet-popup {
  z-index: 10000 !important;
}

.bntListSearch{
  float: right;

  
}
.cluster {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(45deg) scale(var(--ggs,1));
  width: 16px;
  height: 16px;
  border-radius: 0 100% 100% 100%;
  box-shadow: inset 0 0 0 2px
}
.word-wrap-cell {
  white-space: normal;
}
/* .css-1v6lr6e-MuiTreeItem-content.Mui-selected {
  background-color: rgba(204, 110, 22, 0.08);
  width: 150%; 
}
 */
